.parentLoader {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    .loader {
        border: 3px solid #f3f3f3;
        border-radius: 50%;
        border-top: 3px solid #196055;
        width: 30px;
        height: 30px;
        animation: spin 1s linear infinite;
    }
}

.allPageLoader {
    display: flex;
    justify-content: center;
    height: 30%;
    align-items: center;
    width: 100%;
    top: 0 !important;
    bottom: 0 !important;
    .loader {
        border: 3px solid #f3f3f3;
        border-radius: 50%;
        border-top: 3px solid #196055;
        width: 20px;
        height: 20px;
        animation: spin 1s linear infinite;
    }
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

