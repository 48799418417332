@import '../helper.scss';
@import 'assets/styles/variables';

.G-main-form {
    width           : 650px;
    display         : flex;
    flex-wrap       : wrap;
    max-width       : 100%;
    justify-content : space-between;

    > div {
      width : 100%;
    }

    .G-main-form-field, .G-main-form-half-field {
      width         : 100%;
      margin-bottom : 17px !important;
      position: relative;
      .imgSlack {
        position: absolute;
        right: 20px;
        bottom: 15px;
        font-size: 14px;
        pointer-events: none;
      }
    }

    .P-sign-title_us {
      color: #8A8A8A;
      font-size: 14px;
      margin-bottom: 5px;
    }

    .termsCondition {
      display: flex;
      align-items: center;
      .checkbox-label {
        display: flex;
        align-items: center;
        .checkbox-label input[type="checkbox"] {
          margin-right: 10px;
          appearance: none;
          width: 20px;
          height: 20px;
          border: 2px solid #ccc;
          border-radius: 4px;
          outline: none;
          cursor: pointer;
        }
        input {
          width: 30px;
          height: 30px;
          &:checked {
            background-color: grey;
            border-color: grey;
          }
  
        }

        .terms-label {
          margin-left: 5px;
        }
      }
    }

    .sendCodePlaceTitle{
      .placeUpHolder {
        margin-bottom: 3px;
        cursor: text;
      }
    }

    .G-main-form-half-field {
      width : calc(50% - 15px);
    }

    > .G-main-button {
      width : 100%;
    }

    .G-main-input {
      border-radius: 0 !important;
    }

    .phoneField{
      cursor: text;
    }

    


    .firstField{
      .placeUpHolder {
        margin-bottom: 3px;
        cursor: text;
      }
    }

    .signUpPlaceTitle{
      .placeUpHolder {
        margin-bottom: 3px;
        cursor: text;
      }
    }

    .sendCodePlaceTitle{
      .placeUpHolder {
        margin-bottom: 3px;
        cursor: text;
      }
    }

    .secondField {
      position: relative;
      margin-bottom: 10px !important; 
      .placeUpHolder {
        margin-bottom: 3px;
        cursor: text;
      }

      .G-main-input {
        padding-left: 15px;
      }
    }

    .ladda-button {
      border-radius: 12px !important;
      font-size: 16px !important;
      margin-top: 27PX;

    }
}

.G-invalid-field {
  border : 1px solid red !important;
}

.G-input-top-label {
  color         : #8A8A8A;
  font-size     : 14px;
  padding-left  : 0px;
  margin-bottom : 7.5px;
  width: fit-content;
  cursor: text;
}

.G-phone-input-wrapper {
    position : relative;

    .G-input-country-code-main {
      display: flex;
      align-items: center;
      gap: 15px;
      .G-input-country-code {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 96px;
        height        : 54px;
        color         : black;
        border: 1px solid #CFCFCF;
      }
    }



    > .G-main-input {
      padding-left : 80px;
    }
}

.G-main-input, .G-main-textarea {
    width         : 100%;
    border        : 1px solid $light-gray;
    padding       : 12px 20px;
    font-size     : $normal-fs;
    border-radius : 30px;
}

.G-main-select .P-select-header {
    height  : 56px;
    padding : 15px 20px;
    border-radius: 0 !important;
}

.G-main-textarea {
  height : 150px;
  &::-webkit-scrollbar {
    width : 0;
  }
}

.G-main-button {
  color         : white;
  border        : 0;
  padding       : 15px;
  font-size     : 16px;
  background    : $main-color;
  border-radius : 30px;

}

.G-main-ghost-button {
  color           : $main-color;
  cursor          : pointer;
  border          : 2px solid $main-color;
  padding         : 5px 30px;;
  font-size       : $normal-fs;
  background      : transparent;
  border-radius   : 20px;
  text-decoration : none;
}

.G-no-data {
    position   : absolute;
    top        : 50%;
    left       : 50%;
    text-align : center;
    transform  : translate(-50%, -50%);
    color      : gray;

    > .P-desc {
      font-size : 25px;
      cursor: text;
    }
}

.G-dn {
    display : none !important;
}
