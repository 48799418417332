@import 'assets/styles/variables';


.B-news-slider {
    width: 100%;

    .C-news-slider-child-slider {
        display: block;
        width: 100%;
        @media screen and (max-width: $monoSmall-screen) {
            max-height: 295px;
            overflow: hidden;
        }

        .news-slider_item {
            display: flex !important;
            flex-direction: column;
            .news-slider_item_img {
                width: 95%;
                height: 354px;
                box-shadow: 0px 0px 15px #00000017;
                border-radius: 10px;
                cursor: pointer;
                @media screen and (max-width: 1800px) {
                    height: 270px;
                    object-fit: cover;
                    object-position: top;
                }
                @media screen and (max-width: 700px) {
                    height: 194px;
                }
            }
            .news-slider_item_title {
                font-size: 24px;
                color: black;
                margin: 20px 38px 8px 0;
                font-weight: bolder;
                font-size: 20px;
                margin: 18px 0px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                -webkit-line-clamp: 2;
                white-space: normal;
            }
    
            .news-slider_item_description {
                font-size: 13px;
                margin: 0 38px 0 0;
                color: #171717;
                white-space: nowrap;
                overflow: hidden; /* Hide the overflow */
                text-overflow: ellipsis; /* Show ellipsis for overflowed text */
                max-width: 400px;
                @media screen and (max-width: $mediumToSmall-screen) {
                    font-size: 14px;
                    margin: 0;
                }
            }
            @media screen and (max-width: $mediumToSmall-screen) {
                height: 295px;
            }
        }
    }

    .C-news-slider-child {
        display: flex;
        gap: 30px;
        .news-slider_item {
            display: flex !important;
            flex-direction: column;
            width: 100%;
            .news-slider_item_img {
                width: 100%;
                height: 354px;
                box-shadow: 0px 0px 15px #00000017;
                cursor: pointer;
            }
            .news-slider_item_title {
                font-size: 24px;
                color: black;
                margin: 20px 38px 8px 0;
                font-weight: bolder;
            }
    
            .news-slider_item_description {
                font-size: 13px;
                margin: 0 38px 0 0;
                color: #171717;
                white-space: nowrap;
                overflow: hidden; /* Hide the overflow */
                text-overflow: ellipsis; /* Show ellipsis for overflowed text */
                max-width: 400px;
            }

        }
    }

    @media screen and (max-width: $medium-screen) {
        padding-left: 14px !important;
    }

    .slick-slide {
        padding:0 !important;
        margin-right: 0px;
        width: 16% !important;
    }
    .showItem{
        width: fit-content;
        border-radius: 5px;
        padding: 10px 20px;
        text-align: center;
        margin-top: 20px;
        background: #196055;
        color: white;
        margin: 0 auto;
        margin-top: 50px;
    }
}