.globalPopUp {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: fit-content;
    background: rgba(0, 0, 0, 0.267);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    .innerPopUp {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 500px;
        height: 100vh;
        margin:0 20px;
        .P-popup-first,
        .P-popup-second,
        .P-popup-third {
            background-color: #fff;
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            max-width: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin:auto;
        
            img {
                width: 100%;
                height: auto;
                max-height: 500px;
                object-fit: cover;
            }
        
            .P-close-block {
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
                background: #ccc;
                padding: 10px;
                border-radius: 60px;
                svg {
                    line {
                        stroke: white;
                    }
                }
            }
        
            .P-content {
                text-align: center;
                padding: 0 10px;
                h5 {
                    margin: 20px 0;
                    font-size: 24px;
                    text-align: center;
                }
                p {
                    font-size: 20px;
                    margin-bottom: 20px;
                }
        
                .G-dark-white {
                    color: #f0f0f0;
                }
            }
        
            .P-next-block {
                display: flex;
                justify-content: center;
                gap: 10px;
                padding: 10px 20px;
                width: 100%;
                margin-bottom: 20px;
        
            .P-cancel-block {
                display: flex;
                justify-content: center;
                align-items: center;
                .P-cancel {
                    font-size: 16px;
                    color: #007bff;
                    cursor: pointer;
                    transition: color 0.3s;
            
                    &:hover {
                        color: #0056b3;
                    }
                }
            }
        
            .P-positive-button {
                padding: 10px 20px;
                font-size: 16px;
                background-color: #28a745;
                color: #fff;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s;
        
                &:hover {
                background-color: #218838;
                }
            }
            }
        }
        
        .P-popup-second {
            .P-next-block {
            .P-positive-button {
                background-color: transparent;
                color: #007bff;
                border: 1px solid #007bff;
            }
            }
        }
        
        .P-popup-third {
            .P-next-block {
            .P-positive-button {
                background-color: #ff9900;
                color: #fff;
            }
            }
        }
    }

}