@import 'src/assets/styles/variables';

.P-news-page {
  display         : flex;
  flex-wrap       : wrap;
  align-items     : flex-start;
  justify-content : center;
  min-height: unset !important;
  @media screen and (max-width: $medium-screen) {
    padding: 20px 24px !important;
    gap: 20px;
  }

  .mainItem {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 546px;
    gap: 10px;
    @media screen and (max-width: $medium-screen) {
      flex-direction: column-reverse;
      height: fit-content;
      align-items: center;
    }
    .description_field{
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 623px;
      width: 100%;
      cursor: default;
      .date {
        color: #A8A8A8;
        font-size: 18px;
        margin-bottom: 11px;
        cursor: pointer;
      }
      .mainTitle {
        font-size: 40px;
        color: #000000;
        font-weight: bold;
        // font-family: 'Mardoto-Light';
        cursor: pointer;
        @media screen and (max-width: $mediumToSmall-screen) {
          font-size: 18px;
        }
      }
      .description {
        font-size: 18px;
        color: #000000;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 4;
        white-space: normal;
        text-overflow: ellipsis;
        margin-top: 35px;
        cursor: pointer;
        @media screen and (max-width: $mediumToSmall-screen) {
          font-size: 14px;
        }
      }
    }
    .mainImg {
      max-width: 684px;
      width: 100%;
      border-radius: 14px;
      object-fit: cover;
      @media screen and (max-width: $mediumToSmall-screen) {
        height: 254px;
      }
    }

  }

  .allItemsContainer {
    display: flex;
    flex-direction: column;
    gap: 36px;
    margin-top: 10px;

    @media screen and (max-width: $monoSmall-screen) {
      margin-bottom: 80px;
    }
    .allItemsTitle {
      color: #474747;
      font-size: 30px;
      cursor: text;
      @media screen and (max-width: $monoSmall-screen) {
        display: none;
      }
    }
    .allItems {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 63px;
      column-gap: 10px;
      @media screen and (max-width: $monoSmall-screen) {
        row-gap: 20px;
      }
      .items {
        display: flex;
        flex-direction: column;
        max-width: 318px;
        width: 100%;

        @media screen and (max-width: $monoSmall-screen) {
          max-width: 100%;
        }
        cursor: pointer;
        .itemsImg {
          height: 254px;
          width: 100%;
          border-radius: 6px;
          margin-bottom: 17px;
          cursor: pointer;
          object-fit: cover;
        }
        .itemsDate {
          font-size: 16px;
          color: #A8A8A8;
          margin-bottom: 7px;
        }
        .itemsTitle {
          font-size: 18px;
          color: #000000;
          font-weight: bold;
          margin-bottom: 11px;
        }
        .itemsDescription{
          font-size: 16px;
          color: #000000;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 3;
          white-space: normal;
          text-overflow: ellipsis;
        }
      }
    }

  }
}