@import "src/assets/styles/helper";
@import 'src/assets/styles/variables';

.popup {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    // height: 100%;
    .popup-content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
      padding: 10px;
      max-width: 1920px;
      padding: 30px 130px;
      margin: 0 auto;
      height: fit-content;
      background-color: #fff;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      overflow-y: auto;
      @media screen and (max-width: $medium-screen) {
        padding: 10px 14px;
      }

      // Adjust grid for smaller screens
      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }

      // Adjust grid for extra-small screens
      @media screen and (max-width: 480px) {
        grid-template-columns: repeat(2, 1fr); // Fixed two columns for very small screens
        padding: 8px;
      }
    }

    .popup-row {
      color: #000000;
      font-weight: bolder;
      width: fit-content;
      cursor: pointer;
      &:hover {
        color: #196055 !important;
      }
      @media screen and (max-width: 700px) {
        font-weight: bold;
        font-size: 13px;
      }
    }
}
  